import { useMemo } from 'react'

import cssSelectorFmt from 'utils/cssSelectorFmt';

const DefaultClass = "flex items-center justify-center font-inter font-semibold rounded-md disabled:opacity-50";
const DefaultSVGClass = "w-[20px] h-[20px]";

const checkIfSVG = (Icon) => {
  return Icon.$$typeof === Symbol.for('react.forward_ref');
};

const RenderIcon = ({ Icon, iconPosition, disabled }) => {
  const margin = iconPosition === "right" ? "ml-[6px]" : "mr-[6px]";

  if(checkIfSVG(Icon)) return <Icon className={`${DefaultSVGClass} ${disabled && "opacity-50"} ${margin}`} />
  return <img src={Icon} className={`${DefaultSVGClass} ${disabled && "opacity-50"} ${margin}`} />
}

const Button = ({
  text,
  size,
  type,
  variant,
  className,
  icon,
  disabled,
  iconPosition,
  ...props
}) => {
  const Icon = icon;

  const SizeClass = useMemo(() => {
    switch (size) {
      case "xs": {
        return "!py-[4px] !px-[8px] !text-[12px] !leading-[18px]"
      }
      case "s": {
        return "!py-[4px] !px-[8px] !text-[14px] !leading-[20px]"
      }
      case "l": {
        return "!py-[8px] !px-[12px] !text-[14px] !leading-[20px]"
      }
      case "xl": {
        return "!py-[10px] !px-[14px] !text-[14px] !leading-[20px]"
      }
      case "md":
      default: {
        return "py-[6px] px-[10px] text-[14px] leading-[20px]"
      }
    }
  }, [size]);

  const TypeClass = useMemo(() => {
    switch (type) {
      case "secondary": {
        return `text-black-0 bg-white border-[1px] border-solid border-black-3 hover:bg-black-3`
      }
      case "negative": {
        return `text-red-4 bg-white border-[1px] border-solid border-red-4 hover:bg-red-5 ${cssSelectorFmt("hover", ["text-red-5", "border-red-5"])}`
      }
      case "primary":
      default: {
        return `text-white bg-black-0 hover:bg-black-4`
      }
    }
  }, [type]);

  const VariantClass = useMemo(() => {
    switch (variant) {
      case "xs": {
        return "px-[8px] py-[4px]"
      }
      case "md":
      default: {
        return "px-[10px] py-[6px]"
      }
    }
  }, [variant]);

  const IconPositionClass = useMemo(() => {
    switch (iconPosition) {
      case "right": {
        return "px-[8px] py-[4px]"
      }
      case "left":
      default: {
        return ""
      }
    }
  }, [iconPosition]);

  return (
    <button
      {...props}
      disabled={disabled}
      className={`${DefaultClass} ${SizeClass} ${TypeClass} ${VariantClass} ${IconPositionClass} ${className}`}>
      {(!!Icon && iconPosition !== "right") && <RenderIcon disabled={disabled} Icon={Icon} iconPosition={iconPosition} />}
      {text}
      {(!!Icon && iconPosition === "right") && <RenderIcon disabled={disabled} Icon={Icon} iconPosition={iconPosition} />}
    </button>
  )
}

export default Button
